import { useEffect, useState } from "react";
import "./aside.scss";
import logo from "./../../../assets/images/logo/logo.svg";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavItem,
  CNavGroup,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import dot from "./../../../assets/images/dot.png";
import { Link } from "react-router-dom";

const Aside = () => {
  const vars = {
    "--cui-sidebar-header-height": "800px",
  };
  const [activeMenu, setActiveMenu] = useState("");
  const sidebar = [
    {
      name: "In-Bound / Out-Bound",
      link: "/bulk-upload",
       sub: [],
    },
    {
      name: "Barcode Stock ",
      link: "/barcode-update",
       sub: [],
    },
    {
      name: "Products",
      link: "/bulk-product-upload",
       sub: [],
    },
    // {
    //   name: "Bulk Store",
    //   link: "/bulk-store-upload",
    //    sub: [],
    // },
    {
      name: "Store Category",
      link: "/bulk-store-Category",
       sub: [],
    },
    {
      name: "Addons",
      link: "/bulk-addons",
       sub: [],
    },
    
    // {
    //   name: "Store Management ",
    //   link: "#",
    //   sub: [
    //     {
    //       name: "Store Request ",
    //       link: "/store-request",
    //     },
    //     {
    //       name: "Store Listing",
    //       link: "/store-listing",
    //     },
    //   ],
    // },
    // {
    //   name: "Category Management ",
    //   link: "#",
    //   sub: [
    //     {
    //       name: "Store Type ",
    //       link: "/business-type-listing",
    //     },
    //     {
    //       name: "Business Category",
    //       link: "/business-category",
    //     },
    //     {
    //       name: "Unit of measurement",
    //       link: "/unit-of-measurement",
    //     },
    //   ],
    // },
    // {
    //   name: "Product Management ",
    //   link: "/product-listing",
    //   sub: [
        // {
        //   name: "Add Product Request ",
        //   link: "/product-request",
        // },
        // {
        //   name: "Edit Product Request ",
        //   link: "/edit-product-request",
        // },
        // {
        //   name: "Product Listing",
        
        // },
    //   ],
    // },
    // {
    //   name: "Manage Orders",
    //   link: "#",
    //   sub: [
    //     {
    //       name: "Order Listing ",
    //       link: "/order-listing",
    //     },
    //     {
    //       name: "Push Notification",
    //       link: "#",
    //     },
    //   ],
    // },
    // {
    //   name: "CMS",
    //   link: "#",
    //   sub: [
       
    //     {
    //       name: "Faq",
    //       link: "/faq",
    //     },
    //     {
    //       name: "Term and Conditions",
    //       link: "/terms-services",
    //       nodeId: 52,
    //     },
    //     {
    //       name: "Disclaimer",
    //       link: "/disclaimer",
    //     },
    //     {
    //       name: "Privacy Policy",
    //       link: "/privacy-policy",
    //     },
    //     {
    //       name: "Warranty Policy",
    //       link: "/warrenty-policy",
    //     },
    //     {
    //       name: "Delivery Information",
    //       link: "/delivery-information",
    //     },
    //     {
    //       name: "About Baladi",
    //       link: "/about-baladi",
    //     },
    //   ],
    // },
    // {
    //   name: "Manage Customer Listing",
    //   link: "/manage-customer-listing",
    //   // nodeId: 6,
    // },
    // {
    //   name: "Manage Users",
    //   link: "#",
    //   sub: [
    //     {
    //       name: "Manage Picker User",
    //       link: "/manage-user-listing",
    //       // nodeId: 10,
    //     },
    //     {
    //       name: "Manage Financial  User",
    //       link: "/manage-financial-user",
    //       // nodeId: 11,
    //     },
    //   ],
    // },
    // {
    //   name: "Payout Management Listing",
    //   link: "/payout-management-listing",
    //   // nodeId: 7,
    // },
    // {
    //   name: "Driver Onboarding Listing",
    //   link: "/driver-onboarding-listing",
    //   // nodeId: 8,
    // },
    // {
    //   name: "Section Management Listing",
    //   link: "/section-management-listing",
    //   // nodeId: 9,
    // },
    // {
    //   name : "Zone",
    //   link : "/zone-list",
    // },

    // {
    //   name: "Manage User",
    //   link: "/manage-user-listing",
    //   nodeId: 10,
    // },
    // {
    //   name: "Manage Financial  User",
    //   link: "/manage-financial-user",
    //   nodeId: 11,
    // },
    // {
    //   name: "System Settings",
    //   link: "/system-settings",
    //   // nodeId: 12,
    // },
  ];
  let selectedMenu = "";
  if (localStorage.getItem("selectedMenu")) {
    selectedMenu = localStorage.getItem("selectedMenu");
  }
  useEffect(() => {
    const url = window.location.href;
    const extractedPart = url.substring(url.lastIndexOf("/") + 1);
    setActiveMenu(extractedPart);
  }, [selectedMenu]);
  return (
    <>
      <div className="aside-wrapper" style={{ overflow: "scroll" }}>
        <CSidebar style={vars}>
          <CSidebarBrand>
            <img src={logo} />
          </CSidebarBrand>
          <CSidebarNav>
            {sidebar.map((obj, index) => {
              if (obj?.sub?.length) {
                return (
                  <CNavGroup
                    className={selectedMenu == obj.name ? "show" : ""}
                    onClick={(e) => {
                      localStorage.setItem("selectedMenu", obj.name);
                    }}
                    toggler={obj.name}
                    key={`menu-${index}`}
                  >
                    {obj.sub.map((obj1, index1) => {
                      return (
                        <Link
                          key={`menu-link-${index1}`}
                          to={obj1.link}
                          className={
                            "/" + activeMenu == obj1.link
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          <CNavItem key={index1}>{obj1.name}</CNavItem>
                        </Link>
                      );
                    })}
                  </CNavGroup>
                );
              } else {
                return (
                  <Link
                    key={`menu-link-${index}`}
                    to={obj.link}
                    onClick={(e) => {
                      localStorage.removeItem("selectedMenu");
                    }}
                    className={
                      "/" + activeMenu == obj.link
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <CNavItem key={index}>{obj.name}</CNavItem>
                  </Link>
                );
              }
            })}
          </CSidebarNav>
        </CSidebar>
      </div>
    </>
  );
};

export default Aside;
