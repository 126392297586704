import { X_API_KEY } from "./pages/ENUM";
// const baseUrl = "https://localhost:3800";
// const baseUrl = "https://apibulk.baladiexpress.com";
const baseUrl = "https://imsapi.baladiexpress.com";
export default {
  BASE_URL: baseUrl+'/v1',
  X_API_KEY: X_API_KEY,
  IMG_BASE_URL: baseUrl,
  BALADI_STORE_ID: 2,
  ADMIN_ID: 1,
};
